import { getOutlet } from "reconnect.js";
import { navigate as nav } from "gatsby";
import * as PathUtil from "./Utils/PathUtil";

const LoadingOutlet = getOutlet("loading");

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function setLoading(loading, params = {}) {
  const { message = "" } = params;
  setTimeout(() => {
    LoadingOutlet.update({ loading: loading, message: message });
  }, 0);
}

function navigate(nextRoute, options = { loading: false, message: "" }) {
  const currRoute = PathUtil.normalizedRoute();
  nextRoute = PathUtil.normalizedRoute(nextRoute);
  if (currRoute !== nextRoute) {
    if (options?.loading) {
      LoadingOutlet.update({ loading: true, message: options?.message });
      if (typeof options.loading === "number") {
        setTimeout(() => {
          LoadingOutlet.update(false);
        }, options.loading);
      }
    }
    nav(nextRoute);
  } else {
    console.log("path not changed, ignore...");
  }
}

export { delay, setLoading, navigate };
