import React from "react";
import { getNewOutlet, getOutlet, useOutlet } from "reconnect.js";

getNewOutlet(
  "mint",
  { registered: false, latest: 0, history: [] },
  { autoDelete: false }
);

export async function registerMintEvent(nft) {
  const MintOutlet = getOutlet("mint");

  if (MintOutlet.getValue().registered) {
    return;
  }

  MintOutlet.update({
    ...MintOutlet.getValue(),
    registered: true,
    latest: await nft.getTotalSupply(),
  });

  nft.setEventListener("mint", (err, data) => {
    if (!err) {
      const { transactionHash, tokenId } = data;
      MintOutlet.update({
        latest: tokenId,
        history: [
          ...MintOutlet.getValue().history,
          { transactionHash, tokenId },
        ],
      });
    }
  });

  nft.pollEvents();
}

export function CurrMint(props) {
  const [mint] = useOutlet("mint");
  return <div style={{ fontSize: "4rem" }}>{mint.latest}</div>;
}

export function CurrMintSupply(props) {
  const { nft } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
        justifyContent: "center",
        paddingTop: 15,
      }}
    >
      <CurrMint />
      <div style={{ margin: "0px 3px", fontSize: "1.2rem" }}>/</div>
      <div style={{ fontSize: "1.2rem" }}>{nft.tierSupply}</div>
    </div>
  );
}

export function CurrMintTime(props) {
  const { nft } = props;
  const startDate = new Date(nft.saleStartTime * 1000);
  const endDate = new Date(nft.saleEndTime * 1000);
  const nowDate = new Date();
  const [, setUpdateCounter] = React.useState(0);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setUpdateCounter((cnt) => cnt + 1);
    }, 350);

    return () => {
      clearTimeout(timer);
    };
  });

  if (
    !(
      startDate.getTime() <= nowDate.getTime() &&
      nowDate.getTime() < endDate.getTime()
    )
  ) {
    return <h2>Not Open Yet</h2>;
  }

  let seconds = Math.floor((endDate.getTime() - nowDate.getTime()) / 1000);

  const daysLeft = Math.floor(seconds / (3600 * 24));
  seconds -= daysLeft * 3600 * 24;

  const hoursLeft = Math.floor(seconds / 3600);
  seconds -= hoursLeft * 3600;

  const minsLeft = Math.floor(seconds / 60);
  seconds -= minsLeft * 60;

  function digit(num) {
    return ("00" + num.toString()).slice(-2);
  }

  return (
    <>
      <h3 style={{ color: "#ccc" }}>
        {startDate.toLocaleString()} - {endDate.toLocaleString()}
      </h3>
      {daysLeft > 0 && <h2>{`${daysLeft} Day`}</h2>}
      <h2>{`${digit(hoursLeft)}:${digit(minsLeft)}:${digit(seconds)}`}</h2>

      {props.children}
    </>
  );
}
