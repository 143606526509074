const constants = {
  darkTheme: true,
  themeColor: '#F6F1F1',
  themeColor2nd: '#F6F1F1',
  maxWidth: 1024,
  basePadding: 20,
  siteLogo: '/images/revicon_512.png',
  mintLogo: '/images/revicon_512.png',
};

export default constants;
