import React from "react";
import * as AppContext from "./AppContext";
import Layout from "./Components/Layout";

export function PageContainer(props) {
  return (
    <Layout {...props} seo={props.pageContext.seo || {}}>
      {props.children}
    </Layout>
  );
}

export function AppRoot(props) {
  return <AppContext.Provider {...props}>{props.children}</AppContext.Provider>;
}
