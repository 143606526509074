// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-admin-hidealldashboard-js": () => import("./../../../src/Templates/Admin/hidealldashboard.js" /* webpackChunkName: "component---src-templates-admin-hidealldashboard-js" */),
  "component---src-templates-aes-256-index-js": () => import("./../../../src/Templates/AES256/index.js" /* webpackChunkName: "component---src-templates-aes-256-index-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-txt-storage-index-js": () => import("./../../../src/Templates/TxtStorage/index.js" /* webpackChunkName: "component---src-templates-txt-storage-index-js" */)
}

