function normalizedRoute(route) {
  let path = "";
  let query = "";
  let hash = "";

  if (!route) {
    path = window.location.pathname;
    query = window.location.search;
    hash = window.location.hash;
    if (query[0] === "?") {
      query = query.slice(1);
    }
    if (hash[0] === "#") {
      hash = hash.slice(1);
    }
  } else {
    if (route[0] !== "/") {
      // don't normalize external link
      return route;
    }

    let tokens;
    tokens = route.split("#");
    path = tokens[0];
    hash = tokens[1];
    if (hash) {
      tokens = hash.split("?");
      hash = tokens[0];
      query = tokens[1];
    } else {
      tokens = path.split("?");
      path = tokens[0];
      query = tokens[1];
    }
  }

  if (path[path.length - 1] !== "/") {
    path += "/";
  }

  if (hash) {
    return query ? `${path}#${hash}?${query}` : `${path}#${hash}`;
  } else {
    return query ? `${path}?${query}` : path;
  }
}

export { normalizedRoute };
