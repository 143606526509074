import Contract from '../../NFT.json';
import {getOutlet} from 'reconnect.js';

class NFT {
  constructor() {
    this.web3 = null;
    this.eventListeners = {
      mint: null,
    };
  }

  _initTxHistory() {
    const TxHistory = getOutlet('tx-history');
    let prevHistory = [];
    if (window.localStorage) {
      prevHistory = JSON.parse(window.localStorage.getItem('tx-history')) || [];
    }
    TxHistory.update(prevHistory);
  }

  _pushToTxHistory(txRecord) {
    const TxHistory = getOutlet('tx-history');
    if (!txRecord.from) {
      txRecord.from = this.account;
    }
    const nextHistory = [...TxHistory.getValue(), txRecord];
    TxHistory.update(nextHistory);
    if (window.localStorage) {
      window.localStorage.setItem('tx-history', JSON.stringify(nextHistory));
    }
  }

  clearTxHistory() {
    const TxHistory = getOutlet('tx-history');
    TxHistory.update([]);
    if (window.localStorage) {
      window.localStorage.setItem('tx-history', JSON.stringify([]));
    }
  }

  setEventListener(key, listener) {
    this.eventListeners[key] = listener;
  }

  isInitialized() {
    return !!this.web3;
  }

  async init(contractAddr, options = {}) {
    if (this.web3) {
      return;
    }

    this.contractAddr = contractAddr;

    if (typeof window.ethereum === 'undefined') {
      throw new Error('MetaMask is not installed');
    }

    if (typeof window.Web3?.givenProvider === 'undefined') {
      throw new Error('MetaMask cannot find givenProvider');
    }

    this.web3 = new window.Web3(window.Web3.givenProvider);

    this.contract = new this.web3.eth.Contract(Contract.abi, contractAddr);
    console.log('contract methods', this.contract.methods);
    console.log('contract events', this.contract.events);

    this._initTxHistory();
  }

  async checkAndSwithToCorrectChain(targetChain) {
    try {
      let c = await this.web3.eth.getChainId();
      if (c !== targetChain.id) {
      }
    } catch (err) {
      console.error('checkAndSwithToCorrectChain', err);
    }
  }

  async getContractBalance() {
    return await this.web3.eth.getBalance(this.contract.options.address);
  }

  async connect(postFunc = () => 0) {
    this.accounts = await this.web3.eth.requestAccounts();
    console.log('accounts', this.accounts);
    await this.selectAccount();
    postFunc();
  }

  async monitorMint(onReceive) {
    this.contract.events
      .MintEvt()
      .on('data', (event) => {
        const ret = event.returnValues;
        console.log(`MintEvt`, ret);
        onReceive(ret[0], ret[1]);
      })
      .on('error', (err) => {
        console.warn('MintEvt-error', err);
      });
  }

  async pollEvents(interval = 5000) {
    if (!this.pollTimer) {
      let prevTokenId = 0;
      this.pollTimer = setInterval(async () => {
        const tokenId = await this.contract.methods.totalSupply().call();

        if (prevTokenId !== tokenId) {
          console.log(`TokenMinted #${tokenId} polled`);

          const listener = this.eventListeners.mint;
          if (listener) {
            listener(null, {transactionHash: '', tokenId});
          }
        }

        prevTokenId = tokenId;
      }, interval);
    }
  }

  async selectAccount(addr) {
    if (!addr) {
      addr = this.accounts[0];
    }

    this.account = addr;
    console.log('defaultAccount', this.account);
    this.accountBalance = await this.web3.eth.getBalance(this.account);
    console.log('defaultAccountBalance', this.accountBalance);
  }

  async setDrawer(address) {
    try {
      const resp = await this.contract.methods
        .setDrawer(address)
        .send({
          from: this.account,
          gas: 2100000,
        })
        .on('receipt', function (receipt) {});
      console.log('result', resp);
    } catch (err) {}
  }

  async withdraw() {
    try {
      const resp = await this.contract.methods
        .withdraw(this.account)
        .send({from: this.account});

      console.log('result', resp);

      this._pushToTxHistory({
        method: 'withdraw',
        tx: resp.transactionHash,
        data: [],
      });
      return resp;
    } catch (ex) {
      console.warn(ex);
    }
    return null;
  }

  async loadFullContractInfo() {
    try {
      this.dataNumber = await this.contract.methods.getCurrentID().call();
      this.contractBalance = await this.getContractBalance();
      this.drawerAddress = await this.getDrawerAddress();
    } catch (err) {
      console.errro('loadFullContractInfo', err);
    }
  }

  async getDrawerAddress() {
    try {
      return await this.contract.methods.getDrawer().call();
    } catch (err) {
      console.error('getDrawerAddress', err);
    }
  }

  getFee(data, baseFee) {
    let len = Math.ceil(data.length / 128);

    return (len > 10 ? 10 : len) * baseFee;
  }

  async payableMint(data, feeMul = 1.1) {
    try {
      const gasPrice = parseInt((await this.web3.eth.getGasPrice()) * feeMul);
      console.log('gasPrice = ', gasPrice);
      console.log('fee', this.getFee(data, 0.0025).toFixed(4));

      const resp = await this.contract.methods
        .payableMint(data)
        .send({
          from: this.account,
          value: this.web3.utils.toWei(
            this.getFee(data, 0.0025).toFixed(4),
            'ether',
          ),

          gas: 2100000,
          gasPrice,
        })
        .on('receipt', function (receipt) {});
      console.log('result', resp);
    } catch (err) {
      console.error('payableMint', err);
      throw err;
    }
  }

  async getData(id) {
    try {
      if (!this.contract) {
        this.contract = new this.web3.eth.Contract(
          Contract.abi,
          this.contractAddr,
        );
      }
      const resp = await this.contract.methods.savedData(id).call();
      console.log('result', resp);
      return resp;
    } catch (err) {
      console.error('getData', err);
      throw err;
    }
  }

  disconnect(postFunc = () => 0) {
    this.account = null;
    this.accounts = null;
    postFunc();
  }

  async isOptimismNetwork() {
    try {
      const chainId = await this.web3.eth.getChainId();
      const isOptimism = chainId === '0xa';
      const networkType = await this.web3.eth.net.getNetworkType();

      console.log(`Is on Optimism Network: ${isOptimism}`);
      console.log(`Current network: ${networkType}`);

      return isOptimism;
    } catch (error) {
      console.error('isOptimismNetwork', error);
      return false;
    }
  }

  async switchToOP() {
    if (!this.contract) {
      this.contract = new this.web3.eth.Contract(
        Contract.abi,
        this.contractAddr,
      );
    }

    if (!(await this.isOptimismNetwork())) {
      try {
        console.log('!!!!!!! 1');
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{chainId: 0xa}],
        });
        console.log('!!!!!!! 2');
      } catch (error) {
        console.log('!!!!!!! 3', error);
        console.error(error);
      }
    }
  }
}

const nft = new NFT();

export default nft;
