import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useOutlet } from 'reconnect.js';
import 'antd/dist/antd.less';
import './style.css';
import './custom.css';
import useInitializeNft from '../../Hooks/useInitializeNft';
import useGoogleAnalytics from '../../Hooks/useGoogleAnalytics';
import Constants from '../../../data.json';

function Layout({ pageContext, children, location, seo = {} }) {
  const { contractAddr } = pageContext;
  const [dimension] = useOutlet('dimension');

  React.useEffect(() => {
    if (window.location.host.indexOf('www.hideall.xyz') !== -1) {
      window.location.href = 'https://hideall.sam-huang.info/'
    }
  }, [])

  useGoogleAnalytics({
    gaId: Constants.stage === 'prod' ? 'G-93JVP70295' : null,
  });
  useInitializeNft(contractAddr);

  return (
    <>
      <Helmet>
        <title>{Constants.site.title || 'NFT'}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <meta
          name="google-site-verification"
          content="McC9dSAdrGQZhMNhRWvYhpXcRUcQVqpZn8pHSWXk1aM"
        />

        <link rel="icon" href="https://www.hideall.xyz/favicon.png" />

        <meta name="keywords" content={seo.keywords} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />

        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />

        <meta property="twitter:title" content={seo.title} />
        <meta property="twitter:description" content={seo.description} />
        <meta property="twitter:image" content={seo.image} />
        <meta property="twitter:type" content="website" />
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:card" content="summary" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap"
          rel="stylesheet"
        />
        <script src="https://unpkg.com/web3@1.7.1/dist/web3.min.js"></script>
      </Helmet>
      <Wrapper rwd={dimension.rwd}>{children}</Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  --contentMaxWidth: 1200px;
  --contentMinHeight: 600px;
  --topNavBarHeight: 64px;
  --basePadding: 15px 20px;
  --primaryColor: #aace2d;
  --sectionPadding: 50px 100px;
  --sectionMobilePadding: 20px;

  min-height: 100vh;

  background-color: #f6f1f1;

  & button.rev-btn {
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 4px;
    border: 2px solid white;
    min-width: 120px;
    background: transparent;
    transition: all 200ms;

    &:hover {
      box-shadow: 0 8px 15px rgba(255, 255, 255, 0.6);
    }

    &:active {
      box-shadow: 0 4px 10px rgba(255, 255, 255, 0.6);
    }

    &:disabled {
      background: #ccc;
    }
  }

  & button.rev-btn.primary {
    background: linear-gradient(to right, blue, purple),
      linear-gradient(to right, purple, pink);

    &:disabled {
      background: #ccc;
    }
  }
`;

export default Layout;
