import React from "react";
import { getOutlet } from "reconnect.js";

function useDetectDimension(breakpoint = 768) {
  const detectDimension = React.useCallback(() => {
    if (typeof window !== "undefined") {
      const nextDimension = {
        rwd: "mobile",
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      };
      if (window.innerWidth > breakpoint) {
        nextDimension.rwd = "desktop";
      }
      getOutlet("dimension").update(nextDimension);
    }
  }, [breakpoint]);

  const onResize = React.useCallback(() => {
    detectDimension();
  }, [detectDimension]);

  React.useEffect(() => {
    try {
      detectDimension();
      window.addEventListener("resize", onResize);

      return () => {
        window.removeEventListener("resize", onResize);
      };
    } catch (ex) {
      console.warn("Provider:useEffect", ex);
    }
  }, [onResize, detectDimension]);
}

export default useDetectDimension;
