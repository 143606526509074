import React from "react";
import "./AppOutlets";
import { getOutlet } from "reconnect.js";
import ActivityIndicator from "./Components/ActivityIndicator";
import * as AppActions from "./AppActions";
import useDetectDimension from "./Hooks/useDetectDimension";

getOutlet("actions", null, { autoDelete: false }).update({
  ...AppActions,
});

function Provider(props) {
  console.log("AppCtx rendered");
  useDetectDimension();

  return (
    <>
      {props.children}

      <ActivityIndicator />
    </>
  );
}

export { Provider };
