import React from 'react';
import nft from '../Utils/Nft';
import {registerMintEvent} from '../Components/CurrMint';
import {useOutletSetter} from 'reconnect.js';
import * as ErrorHandler from '../Components/Error';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function useInitializeNft(contractAddr) {
  const setUpdateCnt = useOutletSetter('update-counter');

  React.useEffect(() => {
    async function initWeb3() {
      const isInitialized = await nft.isInitialized();

      if (!isInitialized) {
        await delay(1200);

        try {
          await nft.init(contractAddr);
        } catch (ex) {
          console.warn(ex);
          ErrorHandler.setNftInitGlobalError(ex);
          return;
        }

        try {
          // just a test to see if we can reach the contract
          //await nft.getName();
          setUpdateCnt((cnt) => cnt + 1);
          //registerMintEvent(nft);
        } catch (ex) {
          console.warn('useInitializeNft', ex);
          ErrorHandler.setNftLoadContractInfoGlobalError(ex);
          return;
        }
      }
    }

    initWeb3();
  }, [contractAddr, setUpdateCnt]);
}
