import React from "react";
import styled from "styled-components";
import Constants from "../../Constants";
import { getNewOutlet, getOutlet } from "reconnect.js";

getNewOutlet("global-error", null, { autoDelete: false });

export function setNftInitGlobalError(ex) {
  getOutlet("global-error").update({
    msg: "ERR-nft-init",
    ex,
  });
}

export function setNftLoadContractInfoGlobalError(ex) {
  getOutlet("global-error").update({
    msg: "ERR-nft-loadContractInfo",
    ex,
  });
}

export function handleGlobalError() {
  const lastGlobalError = getOutlet("global-error").getValue();

  if (lastGlobalError) {
    if (lastGlobalError.msg === "ERR-nft-init") {
      window.alert("Please install MetaMask correctly then reload the page");
    } else if (lastGlobalError.msg === "ERR-nft-loadContractInfo") {
      window.alert(
        "Please selects correct network in MetaMask then reload the page"
      );
    }
    return lastGlobalError;
  }

  return null;
}

function Error(props) {
  return (
    <Wrapper>
      <div className="container">
        <div className="content">
          <img src={Constants.siteLogo} alt="hero banner" />
          <h2>Fail to load contract</h2>
          <p>Please check if you're in correct network, and reload the page</p>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${Constants.themeColor};

  & .container {
    max-width: 800px;
    margin: 0 auto;

    & .content {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > img {
        width: 250px;
        height: 250px;
        object-fit: contain;
        overflow: hidden;
        border-radius: 8px;
        margin-bottom: 10px;
      }

      & > h2 {
        color: white;
        font-size: 2.5rem;
        margin: 0;
      }

      & > p {
        color: white;
        font-size: 1.5rem;
      }
    }
  }
`;

export default Error;
